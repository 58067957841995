import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

import { UserService } from "../user/user.service";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  isAdmin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            ["projectbeheerder", "beheerder", "superadmin"].indexOf(
              this.userService.user.role,
            ) > -1
          ) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        },
        (err) => {
          return resolve(false);
        },
      );
    });
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            ["projectbeheerder", "beheerder", "superadmin"].indexOf(
              this.userService.user.role,
            ) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: projectbeheerder");
            this.router.navigate(["/validation"]);
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/validation"]);
          return resolve(false);
        },
      );
    });
  }
}
