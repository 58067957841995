<header>
  <img class="logo" src="assets/img/energieklus_logo.png" alt="Energieklus logo" />
</header>
<div class="app-selector-container">
  <h1>Dé software voor de gebouwde omgeving</h1>
  <p>Kies waar je mee aan de slag gaat</p>
  <div class="app-btns">
    <a (click)="scanRouteForRole()" aria-label="Navigate to Warmtescan">
      <button mat-flat-button class="app-selection-btn secondary">Warmtescan</button>
    </a>
    <a [routerLink]="['/coach/navigation']" aria-label="Navigate to Energy Advice">
      <button mat-flat-button class="app-selection-btn secondary">
        Energie advies
      </button>
    </a>
    <a [routerLink]="['/fix/navigation']" aria-label="Navigate to Fix Installatie">
      <button mat-flat-button class="app-selection-btn secondary">Installatie</button>
    </a>
    <a [routerLink]="['/dossier']" aria-label="Navigate to Fix Installatie">
      <button mat-flat-button class="app-selection-btn secondary">Dossier</button>
    </a>
    <a [routerLink]="['/check/navigation']" aria-label="Navigate to Woningcheck">
      <button mat-flat-button class="app-selection-btn secondary">Woningcheck</button>
    </a>
    <a [routerLink]="['/service']" aria-label="Navigate to Fix Installatie">
      <button mat-flat-button class="app-selection-btn secondary">Service</button>
    </a>
  </div>
</div>
