<div class="cookie-banner" *ngIf="showBanner">
  We gebruiken cookies om de ervaring op onze website te verbeteren.
  <div class="cookie-banner-actions">
    <button
      style="
        margin-right: 10px;
        background-color: #00007e;
        border: 2px solid #00007e;
        color: white;
        padding: 10px 30px;
        border-radius: 99999px;
      "
      (click)="giveConsent()"
    >
      Accepteren
    </button>
    <button
      style="
        background-color: white;
        border: 2px solid #00007e;
        color: #00007e;
        padding: 10px 30px;
        border-radius: 99999px;
      "
      (click)="denyConsent()"
    >
      Afwijzen
    </button>
  </div>
</div>
