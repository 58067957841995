import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import posthog from "posthog-js";

import { ProjectService } from "../services/project.service";
import { UserService } from "../user/user.service";

// import '@firebase/auth';

@Injectable()
export class AuthService {
  private authInstance = getAuth();

  constructor(
    public afAuth: AngularFireAuth,
    public projectService: ProjectService,
    public userService: UserService,
  ) {}

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        (res) => {
          posthog.identify(res.user.uid, {
            email: res.user.email,
            method: res.user.providerId,
          });
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        (res) => {
          posthog.identify(res.user.uid, {
            email: res.user.email,
            method: res.user.providerId,
          });
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");

      this.afAuth.signInWithPopup(provider).then(
        (res) => {
          posthog.identify(res.user.uid, {
            email: res.user.email,
            method: res.user.providerId,
          });
          resolve(res);
        },

        (err) => reject(err),
      );
    });
  }

  doRegister(email, password) {
    return new Promise<any>((resolve, reject) => {
      createUserWithEmailAndPassword(this.authInstance, email, password).then(
        (res) => {
          posthog.identify(res.user.uid, {
            email: res.user.email,
            method: res.user.providerId,
          });
          resolve(res);
        },
        (err) => reject(err),
      );
    });
  }

  doLogin(email, password) {
    return new Promise<any>((resolve, reject) => {
      signInWithEmailAndPassword(this.authInstance, email, password).then(
        (res) => {
          posthog.identify(res.user.uid, {
            email: res.user.email,
            method: res.user.providerId,
          });
          resolve(res);
        },
        (err) => reject(err),
      );
    });
  }

  doPasswordReset(email) {
    return new Promise<any>((resolve, reject) => {
      sendPasswordResetEmail(this.authInstance, email).then(
        (res) => {
          resolve(true);
        },
        (err) => reject(err),
      );
    });
  }

  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (this.authInstance.currentUser) {
        posthog.reset();
        this.afAuth.signOut();
        resolve();
      } else {
        reject();
      }
    });
  }
}
