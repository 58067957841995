import { Injectable } from "@angular/core";

export interface DrawPoints {
  x: number;
  y: number;
}

@Injectable({
  providedIn: "root",
})
export class CanvasService {
  constructor() {}

  public deleteClickedPolygon(polygons, index: number) {
    if (index >= 0) {
      polygons.splice(index, 1);
    } else {
      alert("Error: cannot delete polygon, because no polygons were selected");
    }
  }

  public addPolygonPoints(event, drawPoints: DrawPoints[]) {
    let x = event.offsetX;
    let y = event.offsetY;
    if (
      // In case we hit the first circle again
      event.target.tagName == "circle" &&
      event.target.attributes.cx.value == drawPoints[0].x &&
      (event.target.attributes.cy.value = drawPoints[0].y)
    ) {
      // Set the last point to be exactly equal to the first point when closing hte polygon
      x = drawPoints[0].x;
      y = drawPoints[0].y;
    }

    drawPoints.push({ x: x, y: y });
    let distance = Math.sqrt(
      Math.pow(drawPoints[0].x - x, 2) + Math.pow(drawPoints[0].y - y, 2),
    );
    return distance;
  }

  public drawPointsToPolygonPoints(drawPoints: DrawPoints[]) {
    drawPoints.pop();
    let polygonPoints = "";
    for (let idx in drawPoints) {
      polygonPoints +=
        drawPoints[idx].x.toString() + "," + drawPoints[idx].y.toString() + " ";
    }
    return polygonPoints.trim();
  }
}
