import { Component } from "@angular/core";
import { MatButton } from "@angular/material/button";
import posthog from "posthog-js";

@Component({
  selector: "app-cookie-consent-banner",
  templateUrl: "./cookie-consent-banner.component.html",
  styleUrls: ["./cookie-consent-banner.component.css"],
})
export class CookieConsentBannerComponent {
  showBanner: boolean = true;

  constructor() {
    // Check if user has already made a choice
    const consentGiven = localStorage.getItem("ek-cookie-consent");
    if (consentGiven !== null) {
      this.showBanner = false;
    }
  }

  giveConsent() {
    localStorage.setItem("ek-cookie-consent", "true");
    posthog.opt_in_capturing();
    this.showBanner = false;
  }

  denyConsent() {
    localStorage.setItem("ek-cookie-consent", "false");
    posthog.opt_out_capturing();
    this.showBanner = false;
  }
}
