<div class="modal-header">
  <h3>{{ title_text }}</h3>
  <button
    type="button"
    class="close mb-0"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- CREATE PROJECT BODY -->
<div *ngIf="edit_mode == 'create'" class="m-1 body modal-body">
  <form class="form-auth-small">
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="project_name"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          Projectnaam
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="project_name"
          placeholder=""
          [(ngModel)]="new_project.name"
        />
      </div>
      <div class="row" *ngIf="project.type == 'scan'">
        <label style="font-weight: 400" class="col-4 col-form-label control-label">
          Type:
        </label>
        <select
          class="col-8"
          [(ngModel)]="project_default_opt_val"
          name="project_default_opt_val"
        >
          <!-- These values are are not a bug! An address for an afmeldproject has the default state of 'opt-in' -->
          <option value="opt-in">Afmeldproject</option>
          <option value="opt-out">Aanmeldproject</option>
        </select>
        <!--
          The below kind of select button is much prettier, but I couldn't get it
          to work. Clicking it never expanded the selection options.
        -->
        <!-- <button color="primary" class="col-8 selectbutton" mat-raised-button>
          <mat-select [(ngModel)]="project_type" name="project_type">
            <mat-option value="opt-out">Afmeldproject</mat-option>
            <mat-option value="opt-in">Aanmeldproject</mat-option>
          </mat-select>
        </button> -->
      </div>
      <!-- <div class="row">
        <label
          style="font-weight: 400"
          class="col-4 col-form-label control-label">
          Type:
        </label>
        <input class="col-8" [(ngModel)]="project_type" name="project_type" disabled></input>
      </div> -->
    </div>
  </form>
</div>

<!-- EDIT PROJECT BODY -->
<div *ngIf="edit_mode == 'edit'" class="m-1 body modal-body">
  <form class="form-auth-small">
    <!-- DISABLED PROJECT DETAILS -->
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="project_name"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          Naam
        </label>
        <input
          required
          disabled
          class="col-8 project-form-input-textbox Invulveld"
          name="project_name"
          placeholder=""
          [(ngModel)]="new_project.name"
        />
      </div>
    </div>
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="bucket"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          bucket
        </label>
        <input
          required
          disabled
          class="col-8 project-form-input-textbox Invulveld"
          name="bucket"
          placeholder=""
          [(ngModel)]="new_project.bucket"
        />
      </div>
    </div>

    <!-- EDITABLE PROJECT DETAILS -->
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="addresses"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          addresses
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="addresses"
          placeholder=""
          [(ngModel)]="new_project.addresses"
        />
      </div>
    </div>
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="addresses_carto"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          addresses_carto
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="addresses_carto"
          placeholder=""
          [(ngModel)]="new_project.addresses_carto"
        />
      </div>
    </div>
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="route_carto"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          route_carto
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="route_carto"
          placeholder=""
          [(ngModel)]="new_project.route_carto"
        />
      </div>
    </div>
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="action_text"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          action_text
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="action_text"
          placeholder=""
          [(ngModel)]="new_project.action_text"
        />
      </div>
    </div>
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="back_text"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          back_text
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="back_text"
          placeholder=""
          [(ngModel)]="new_project.back_text"
        />
      </div>
    </div>
    <div class="form-group container mt-3">
      <div class="row">
        <label
          for="logo"
          style="font-weight: 400"
          class="col-4 col-form-label control-label"
        >
          logo
        </label>
        <input
          required
          class="col-8 project-form-input-textbox Invulveld"
          name="logo"
          placeholder=""
          [(ngModel)]="new_project.logo"
        />
      </div>
    </div>
  </form>
</div>
<div class="modal-footer mt-3 ml-3 mb-3">
  <button class="regularbutton" type="submit" (click)="post_project()">
    {{ submit_button_text }} <span class="ml-1 right-triangle"></span>
  </button>
  <button
    class="regularbutton"
    style="width: 100px"
    type="button"
    (click)="activeModal.close('Close click')"
  >
    Annuleren
  </button>
</div>
