import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { AdminGuard } from "../auth/admin.guard";
import { AuthService } from "../auth/auth.service";
import { AppMenuComponent } from "../components/app-menu/app-menu.component";
import { Project } from "../interfaces/api-heatbrain";
import { ProjectFormModalComponent } from "../project-components/project-form-modal/project-form-modal.component";
import { ProjectService } from "../services/project.service";
import { UserService } from "../user/user.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  appropriateClass: string = "";
  isAdmin: boolean;
  projectControl = new FormControl(null, Validators.required);
  isHandset$: Observable<boolean>;

  @ViewChild(MatSelect) projectSelect: MatSelect;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public router: Router,
    public authService: AuthService,
    public userService: UserService,
    public projectService: ProjectService,
    public adminGuard: AdminGuard,
    private modalService: NgbModal,
    public appMenu: AppMenuComponent,
  ) {
    this.getScreenHeight();
    this.initializeAdminGuard();
    this.appMenu.appChanged.emit(this.appMenu.currentApp);
  }

  ngOnInit() {
    this.initializeProjectSelection();
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  }

  @HostListener("window:resize", ["$event"])
  getScreenHeight(event?) {
    if (window.innerHeight <= 700) {
      this.appropriateClass = "bottomRelative";
    } else {
      this.appropriateClass = "bottomStick";
    }
  }

  initializeAdminGuard() {
    this.adminGuard.isAdmin().then((admin) => {
      this.isAdmin = admin;
    });
  }

  onAppUpdate(app: string | null = null) {
    if (app === "scan") {
      this.projectService.get_scan_projects();
    } else if (app === "fix") {
      this.projectService.get_energieklus_projects();
    } else if (app === "coach") {
      this.projectService.get_energieklus_projects();
    } else if (app === "check") {
      this.projectService.get_energieklus_projects();
    } else if (app === "voucher") {
      this.projectService.get_energieklus_projects();
    } else {
      this.projectService.projects = [];
    }
  }

  initializeProjectSelection() {
    const projectId = `${this.appMenu.currentApp}-project`;
    const projectData = localStorage.getItem(projectId);
    if (projectData !== null) {
      let project = JSON.parse(projectData) as Project;
      if (project.project_type !== this.appMenu.currentApp) project = null;
      this.projectService.selected_project = project;
      this.projectService.emitSelectedProject();
    }
  }

  logout() {
    this.userService.onLogout();
    this.authService.doLogout();
    this.router.navigate(["/login"]);
  }

  public updateSelectedProject() {
    localStorage.setItem(
      `${this.appMenu.currentApp}-project`,
      JSON.stringify(this.projectService.selected_project),
    );
    this.projectService.emitSelectedProject();
  }

  public onCreateProjectClick() {
    const modalRef = this.modalService.open(ProjectFormModalComponent, {
      size: "lg",
      centered: true,
      windowClass: "project-edit-form",
    });
    modalRef.componentInstance.edit_mode = "create";
    modalRef.componentInstance.setProjectType(this.appMenu.currentApp);
  }

  ngAfterContentChecked() {
    if (
      this.projectSelect !== undefined &&
      this.projectService.selected_project !== null
    ) {
      this.projectSelect.options.forEach((proj) => {
        if (proj !== undefined) {
          if (proj.value.id == this.projectService.selected_project.id) {
            proj.select();
          }
        }
      });
    }
  }
}
