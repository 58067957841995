// don't import { firestore }
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import posthog from "posthog-js";

import { User } from "../interfaces/user";
import { CONTENT_TYPE, Request } from "../services/api-heatbrain/request";

export const Legals = ["superadmin", "projectbeheerder", "beheerder"];

@Injectable()
export class UserService {
  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private http: HttpClient,
  ) {}
  public user: User | null = null;
  private firebaseUserCache: firebase.User | null = null;
  public legals: string[] = Legals;

  getCurrentUser(optional: boolean = false) {
    return new Promise<firebase.User | null>((resolve, reject) => {
      // If the firebase user has already been cached and checked then return cache.
      if (this.firebaseUserCache !== null) {
        resolve(this.firebaseUserCache);
        return;
      }

      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser === null) {
          this.user = null;
          if (!optional) {
            reject();
          } else {
            resolve(null);
          }
          return;
        }

        firebaseUser.getIdToken().then((idToken) => {
          const req = new Request();
          req.setUrlParameters(["assignees", firebaseUser.email]);
          req.setHeaders(CONTENT_TYPE.JSON);
          this.http.get<User>(req.getUrl(), req.getOptions(idToken)).subscribe(
            (heatUser) => {
              // @ts-ignore

              this.user = heatUser;
              this.firebaseUserCache = firebaseUser;
              posthog.setPersonProperties({
                role: this.user.role,
              });
              resolve(firebaseUser);
            },
            (err) => {
              this.user = null;
              reject(err);
            },
          );
        });
      });
    });
  }

  onLogout() {
    //Reset user cache on logout.
    this.firebaseUserCache = undefined;
  }
}
