<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>
      <ng-container [ngSwitch]="appMenu.currentApp">
        <!-- Add cases for Administratie/Woningcheck etc. when pos. -->
        <img
          *ngSwitchCase="'scan'"
          class="logo"
          src="/assets/img/project_logos/Warmtescan_logo.png"
        />
        <img
          *ngSwitchCase="'fix'"
          class="logo"
          src="/assets/img/project_logos/Installatie_logo.png"
        />
        <img
          *ngSwitchCase="'coach'"
          class="logo"
          src="/assets/img/project_logos/Energieadvies_logo.png"
        />
        <img
          *ngSwitchCase="'check'"
          class="logo"
          src="/assets/img/project_logos/Woningcheck_logo.png"
        />
        <img
          *ngSwitchCase="'voucher'"
          class="logo"
          src="/assets/img/project_logos/Voucher_logo.png"
        />
      </ng-container>
    </mat-toolbar>

    <button class="sidebarbutton projectselector" mat-raised-button>
      <mat-select
        class=""
        (selectionChange)="updateSelectedProject()"
        [(ngModel)]="projectService.selected_project"
        placeholder="Kies project"
      >
        <mat-option *ngFor="let project of projectService.projects" [value]="project">
          {{ project.name }}
        </mat-option>
      </mat-select>
    </button>

    <div>
      <button
        class="sidebarbutton projectcreator"
        mat-raised-button
        *ngIf="
          ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(
            userService.user.role
          ) > -1
        "
        (click)="onCreateProjectClick()"
      >
        <span class="material-icons" style="font-size: 18px">add</span> Nieuw project
      </button>
    </div>

    <!-- SCAN content -->
    <ng-container *ngIf="appMenu.currentApp === 'scan'; else fixContent">
      <ng-container *ngTemplateOutlet="scanContent"></ng-container>
    </ng-container>

    <!-- FIX content -->
    <ng-template #fixContent>
      <ng-container *ngIf="appMenu.currentApp === 'fix'; else checkContent">
        <ng-container *ngTemplateOutlet="fixContentTemplate"></ng-container>
      </ng-container>
    </ng-template>

    <!-- CHECK content -->
    <ng-template #checkContent>
      <ng-container *ngIf="appMenu.currentApp === 'check'; else voucherContent">
        <ng-container *ngTemplateOutlet="checkContentTemplate"></ng-container>
      </ng-container>
    </ng-template>

    <!-- CHECK content -->
    <ng-template #voucherContent>
      <ng-container *ngIf="appMenu.currentApp === 'voucher'; else coachContent">
        <ng-container *ngTemplateOutlet="voucherContentTemplate"></ng-container>
      </ng-container>
    </ng-template>

    <!-- COACH content -->
    <ng-template #coachContent>
      <ng-container *ngTemplateOutlet="coachContentTemplate"></ng-container>
    </ng-template>

    <!-- Bottom navigation links -->
    <mat-nav-list [ngClass]="appropriateClass">
      <mat-list-item
        [matMenuTriggerFor]="menu"
        *ngIf="
          ['valideur-klusser', 'projectbeheerder', 'beheerder', 'superadmin'].indexOf(
            userService.user.role
          ) > -1
        "
      >
        <mat-icon matListIcon>apps</mat-icon>
        <h4 matLine>Apps</h4>
      </mat-list-item>
      <mat-menu #menu="matMenu" xPosition="after" yPosition="below">
        <app-app-menu (appChanged)="onAppUpdate($event)"></app-app-menu>
      </mat-menu>
      <mat-list-item (click)="logout()">
        <mat-icon matListIcon>logout</mat-icon>
        <h4 matLine>Uitloggen</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- SCAN Content Template -->
<ng-template #scanContent>
  <mat-nav-list>
    <mat-list-item
      *ngIf="
        [
          'valideur',
          'valideur-klusser',
          'expert',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
      routerLink="/scan/validation"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>brush</mat-icon>
      <h4 matLine>Validatie</h4>
    </mat-list-item>
    <mat-list-item routerLink="/scan/acquisition" [routerLinkActive]="['is-active']">
      <mat-icon matListIcon>camera_alt</mat-icon>
      <h4 matLine>Inwinning</h4>
    </mat-list-item>
    <mat-list-item
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
      routerLink="/scan/projects"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>view_list</mat-icon>
      <h4 matLine>Projectbeheer</h4>
    </mat-list-item>
    <mat-list-item
      *ngIf="
        [
          'projectbeheerder',
          'beheerder',
          'superadmin',
          'valideur',
          'valideur-klusser',
          'expert'
        ].indexOf(userService.user.role) > -1
      "
      routerLink="/scan/analytics"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>show_chart</mat-icon>
      <h4 matLine>Analytics</h4>
    </mat-list-item>
    <mat-list-item
      *ngIf="['beheerder', 'superadmin'].indexOf(userService.user.role) > -1"
      routerLink="/scan/users"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>manage_accounts</mat-icon>
      <h4 matLine>Gebruikers</h4>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

<!-- FIX Content Template -->
<ng-template #fixContentTemplate>
  <mat-nav-list>
    <mat-list-item
      routerLink="/fix/projects"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>view_list</mat-icon>
      <h4 matLine>Projectbeheer</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/fix/activity-feed"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>assignment</mat-icon>
      <h4 matLine>Activiteiten</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/fix/navigation"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>navigation</mat-icon>
      <h4 matLine>Navigatie</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/fix/planning"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>calendar_month</mat-icon>
      <h4 matLine>Mijn werk</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/fix/analytics"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>show_chart</mat-icon>
      <h4 matLine>Analytics</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/fix/my-inventory"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>card_travel</mat-icon>
      <h4 matLine>Mijn voorraad</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/fix/inventory"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>local_shipping</mat-icon>
      <h4 matLine>Fix voorraad</h4>
    </mat-list-item>

    <mat-list-item
      *ngIf="['beheerder', 'superadmin'].indexOf(userService.user.role) > -1"
      routerLink="/fix/users"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>manage_accounts</mat-icon>
      <h4 matLine>Gebruikers</h4>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

<!-- COACH Content Template -->
<ng-template #coachContentTemplate>
  <mat-nav-list>
    <mat-list-item
      routerLink="/coach/projects"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>view_list</mat-icon>
      <h4 matLine>Projectbeheer</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/coach/activity-feed"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>assignment</mat-icon>
      <h4 matLine>Activiteiten</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/coach/navigation"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>navigation</mat-icon>
      <h4 matLine>Navigatie</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/coach/planning"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>calendar_month</mat-icon>
      <h4 matLine>Mijn werk</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/coach/analytics"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>show_chart</mat-icon>
      <h4 matLine>Analytics</h4>
    </mat-list-item>

    <mat-list-item
      *ngIf="['beheerder', 'superadmin'].indexOf(userService.user.role) > -1"
      routerLink="/coach/users"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>manage_accounts</mat-icon>
      <h4 matLine>Gebruikers</h4>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

<!-- Check Content Template -->
<ng-template #checkContentTemplate>
  <mat-nav-list>
    <mat-list-item
      routerLink="/check/projects"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>view_list</mat-icon>
      <h4 matLine>Projectbeheer</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/check/activity-feed"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>assignment</mat-icon>
      <h4 matLine>Activiteiten</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/check/navigation"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>navigation</mat-icon>
      <h4 matLine>Navigatie</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/check/planning"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>calendar_month</mat-icon>
      <h4 matLine>Mijn werk</h4>
    </mat-list-item>

    <mat-list-item
      *ngIf="['beheerder', 'superadmin'].indexOf(userService.user.role) > -1"
      routerLink="/check/users"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>manage_accounts</mat-icon>
      <h4 matLine>Gebruikers</h4>
    </mat-list-item>
  </mat-nav-list>
</ng-template>

<!-- Voucher Content Template -->
<ng-template #voucherContentTemplate>
  <mat-nav-list>
    <mat-list-item
      routerLink="/voucher/projects"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>view_list</mat-icon>
      <h4 matLine>Projectbeheer</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/voucher/activity-feed"
      [routerLinkActive]="['is-active']"
      *ngIf="
        ['projectbeheerder', 'beheerder', 'superadmin'].indexOf(userService.user.role) >
        -1
      "
    >
      <mat-icon matListIcon>assignment</mat-icon>
      <h4 matLine>Activiteiten</h4>
    </mat-list-item>
    <mat-list-item
      routerLink="/voucher/navigation"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>navigation</mat-icon>
      <h4 matLine>Navigatie</h4>
    </mat-list-item>

    <mat-list-item
      routerLink="/voucher/planning"
      [routerLinkActive]="['is-active']"
      *ngIf="
        [
          'klusser',
          'valideur-klusser',
          'projectbeheerder',
          'beheerder',
          'superadmin'
        ].indexOf(userService.user.role) > -1
      "
    >
      <mat-icon matListIcon>calendar_month</mat-icon>
      <h4 matLine>Mijn werk</h4>
    </mat-list-item>

    <mat-list-item
      *ngIf="['beheerder', 'superadmin'].indexOf(userService.user.role) > -1"
      routerLink="/voucher/users"
      [routerLinkActive]="['is-active']"
    >
      <mat-icon matListIcon>manage_accounts</mat-icon>
      <h4 matLine>Gebruikers</h4>
    </mat-list-item>
  </mat-nav-list>
</ng-template>
