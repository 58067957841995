import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import posthog from "posthog-js";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  email: string = "";
  password: string = "";
  errorMessage: string = "";
  successMessage: string = "";
  resetPassword: boolean = false;

  @Input() callbackFunction: Function; // Input for the callback function
  @Input() callbackArgs;
  @Input() emailInput: string | null = null;

  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    if (this.emailInput) {
      this.email = this.emailInput;
    }
  }

  handleEmailChange(e) {
    this.email = e.target.value;
    this.errorMessage = "";
  }

  handlePasswordChange(e) {
    this.password = e.target.value;
    this.errorMessage = "";
  }

  toggleResetPassword() {
    this.resetPassword = !this.resetPassword;
    this.successMessage = "";
  }

  tryFacebookLogin() {
    this.authService.doFacebookLogin().then((res) => {
      posthog.capture("user_login", { method: "facebook" });
      this.router.navigate(["/"]);
    });
  }

  tryTwitterLogin() {
    this.authService.doTwitterLogin().then((res) => {
      posthog.capture("user_login", { method: "twitter" });
      this.router.navigate(["/"]);
    });
  }

  tryGoogleLogin(redirectTo: string = "/") {
    this.authService.doGoogleLogin().then((res) => {
      if (this.callbackFunction && this.callbackArgs) {
        this.callbackFunction(this.callbackArgs);
      } else {
        this.router.navigate([redirectTo]);
      }
      posthog.capture("user_login", { method: "google" });
      // this.router.navigate([redirectTo]);
    });
  }

  tryLogin(redirectTo: string = "/") {
    this.authService.doLogin(this.email, this.password).then(
      (res) => {
        console.log(this.callbackArgs, this.callbackFunction);
        if (this.callbackFunction && this.callbackArgs) {
          this.callbackFunction(this.callbackArgs);
        } else {
          console.log("REDIRECTING");
          this.router.navigate([redirectTo]);
        }
        this.email = this.password = "";
        posthog.capture("user_login", { method: "email_password" });
      },
      (err) => {
        if (err.code === "auth/invalid-email") {
          this.errorMessage = "Ongeldig e-mailadres";
        } else if (err.code === "auth/missing-password") {
          this.errorMessage = "Vul een wachtwoord in";
        } else if (err.code === "auth/missing-email") {
          this.errorMessage = "Vul een e-mailadres in";
        } else if (err.code === "auth/wrong-password") {
          this.errorMessage = "Onjuist wachtwoord";
        } else if (err.code === "auth/user-not-found") {
          this.errorMessage = "Gebruiker niet gevonden";
        } else {
          this.errorMessage = "Onbekende fout opgetreden. Probeer het later opnieuw.";
        }
      },
    );
  }

  sendPasswordResetLink() {
    return this.authService.doPasswordReset(this.email).then(
      (res) => {
        if (res) {
          this.successMessage = "De herstel link is naar je email verzonden";
        }
      },
      (err) => {
        if (err.code === "auth/invalid-email") {
          this.errorMessage = "Ongeldig e-mailadres";
        } else if (err.code === "auth/missing-email") {
          this.errorMessage = "Vul een e-mailadres in";
        } else if (err.code === "auth/user-not-found") {
          this.errorMessage = "Gebruiker niet gevonden";
        } else {
          this.errorMessage = "Onbekende fout opgetreden. Probeer het later opnieuw.";
        }
      },
    );
  }
}
