import { Thermogram } from "./thermogram";

type UUID = string;

export class Project {
  id: string;
  name: string;
  slug: string;
  organization_id: string;
  bucket: string;
  addresses: string;
  addresses_carto: string;
  route_carto: string;
  action_text: string;
  back_text: string;
  logo: string;
  opt_capacity: number;
  opt_deadline: string;
  opted_in: number;
  sample_checker: string;
  expert: string;
  project_type: string;
}

export class Address {
  id: string;
  project_id: string;
  geometry: any;
  straat: string;
  huisnummer: number;
  huisletter: string;
  toevoeging: string;
  postcode: string;
  woonplaats: string;
  bouwjaar: number;
  thermogram: string;
  identificatie: string;
  gebruiksdoel: string;
  aantal_verblijfsobjecten: string;
  opt_access_code: string;
  opt_timestamp: string;
  comment: string;
  opt_state: string;
  opt_email: string;
  not_home_ts: string | null;

  public addressString(short: boolean = false): string {
    let addr = this.straat;
    if (short && addr.length >= 14) {
      addr = addr.slice(0, 14) + "..";
    }
    addr += " " + this.huisnummer;
    if (this.huisletter) addr += this.huisletter;
    if (this.toevoeging) addr += " " + this.toevoeging;
    return addr;
  }

  public optedIn(): boolean {
    return this.opt_state === "opt-in";
  }

  public optedOut(): boolean {
    return this.opt_state === "opt-out";
  }

  public wasNotHome(): boolean {
    return this.not_home_ts !== null && this.not_home_ts !== undefined;
  }
}

export class ScanAnalysis {
  id: string;
  address_id: string;
  assignee: string | null;
  assistance_needed: boolean;
  quality_index: number;
  ai_result_json: ScanAnalysisData;
  ai_result_path: string;
  validation_path: string;
  bad_photo: boolean;
  sample_check: boolean;
  note: string | null;
  report_path: string;
  report_type: string;
  deliver: boolean;
  seconds_taken: number | null;
}

export class ScanAnalysisData {
  thermograms: Thermogram[];
  address: string;
  postal_code: string;
  city: string;
  building_geometry: { type: string; coordinates: number[][][][] };
  building_crs: string;
  building_year: string;
  debug: boolean;
  generation_date: string;
  palette: string;
  project: string;
  type_report: string;
  version: number;
}

export class ReportText {
  text: string;
}

export class PullBlob {
  blob: string;
  destination_filename: string;
}

export class PushBlob {
  filename: string;
  destination_blob: string;
}

export class AnalysisResult {
  input_analysis: string;
  json_name: string;
}

export class AssigneeChange {
  analyses: string[];
  assignee: string;
}

export class Municipality {
  code: string;
}

export class ProjectSettings {
  project_id: string;
  confirmation_email: string;
  n_fixes: number;
  timeslot_window: number;
  visit_duration: number;
  fixes_rate_per_visit: number;
  advice_rate_per_visit: number;
  target_visits: number;
  area_filter: string | null;
  woz_cutoff: number | null;
  coupled_voucher_project: number | null;
}

export class WorkLog {
  datetime_start: string;
  duration: number;
  work_log_type: string;
  project_id: string;
  description: string;
  reporter: string;
}

export interface IVisitation {
  id: string;
  project_id: string;
  timeslot: Date;
  visitor: string;
  visitee: string | null;
  phone_number: string;
  started_at: Date | null;
  finished_at: Date | null;
  status: string | null;
  name: string;
  timeslote_window: number | null;
  region: string;
  opt_code: string | null;
}

export class Note {
  id: UUID;
  user_name: string;
  address_id: UUID;
  note_time: Date;
  note_text: string;
  scope: JSON;
  scope_label: string;
}

export class VisitationInfo {
  id: string;
  project_id: string;
  project_name: string | null;
  timeslot: string;
  region: string | null;
  region_pc4_codes: string | null;
  visitor_email: string | null;
  visitee_id: string | null;
  visitee_phone_number: string | null;
  visitee_email: string | null;
  visitee_name: string;
  address_id: string | null;
  visitee_postcode: string | null;
  visitee_straat: string | null;
  visitee_huisnummer: number;
  visitee_huisletter: string | null;
  visitee_toevoeving: string | null;
  visitee_plaats: string | null;
  visitee_analysis: string | null;
  visitee_analysis_report_ready: boolean | null;
  visitee_analysis_report_sent: boolean | null;
  action: string | null;
  timeslot_window: number | null;
  visit_duration: number | null;
  notes?: Note[];
}

export class Visitation {
  id: string;
  project_id: string;
  timeslot: string;
  region: string | null;
  region_pc4_codes: string | null;
  visitor: string;
  visitee: string | null;
  phone_number: string;
  email: string;
  started_at: string | null;
  finished_at: string | null;
  timeslot_window: number | null;
  status: string | null;
  datetime: Date;
  name: string | null;

  public available(pastOkay: boolean = false): boolean {
    /**
     * Returns whether or not this visitation is available for booking.
     *
     * @param pastOkay - Whether or not it is okay if the timeslot lies in the past.
     *    This matters, because we allow normal users to only book timeslots in the future,
     *    but project members can also book in the past.
     *
     * @returns True if available for booking, otherwise false.
     */
    return this.visitee === null && (pastOkay || this.date() > new Date(Date.now()));
  }

  public report_sent(): boolean {
    return this.finished_at !== null;
  }

  public planned(): boolean {
    return (
      this.visitee !== null &&
      this.date() > new Date(Date.now()) &&
      this.started_at === null &&
      this.finished_at === null
    );
  }

  public inProgress(): boolean {
    return (
      this.visitee !== null && this.started_at !== null && this.finished_at === null
    );
  }

  public completed(): boolean {
    return (
      this.visitee !== null && this.started_at !== null && this.finished_at !== null
    );
  }

  public notVisited(): boolean {
    return (
      this.visitee !== null &&
      ((this.date() < new Date(Date.now()) &&
        this.started_at === null &&
        this.finished_at === null) ||
        (this.timeslot === null && this.status === "canceled last-minute"))
    );
  }

  public date(): Date | null {
    return this.timeslot ? new Date(this.timeslot) : null;
  }

  public timeslotStringDutch(): string {
    return this.date().toLocaleString("nl-NL");
  }

  public windowStart(): Date {
    let start = this.date();
    start.setTime(start.getTime() - (60 * 60 * 1000 * this.timeslot_window) / 2);
    return start;
  }

  public windowEnd(): Date {
    let end = this.date();
    end.setTime(end.getTime() + (60 * 60 * 1000 * this.timeslot_window) / 2);
    return end;
  }

  static sortComparer(a: Visitation, b: Visitation) {
    if (a.date().getTime() > b.date().getTime()) return 1;
    else if (b.date().getTime() > a.date().getTime()) return -1;
    else return 0;
  }
}

export interface NotHome {
  address_id: string;
  not_home_ts: string;
}
