import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";

import { SharedLoginModule } from "../shared-modules/shared-login.module";
import { AuthRoutingModule } from "./auth-routing.module";

@NgModule({
  declarations: [],
  imports: [
    SharedLoginModule,
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    MatButtonModule,
  ],
  exports: [SharedLoginModule, CommonModule, FormsModule, MatButtonModule],
})
export class AuthModule {}
