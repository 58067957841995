import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

import { UserService } from "../user/user.service";

@Injectable()
export class BewonerGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            [
              "bewoner",
              "superadmin", // Honestly I think this should be removed, but it is nice to keep it for now during dev
            ].indexOf(this.userService.user.role) > -1
          ) {
            return resolve(true);
          } else {
            alert(
              "Actie niet toegestaan. Alleen bewoners kunnen deze informatie bekijken",
            );
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}

@Injectable()
export class KlusGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            [
              "klusser",
              "valideur-klusser",
              "adviseur",
              "projectbeheerder",
              "beheerder",
              "superadmin",
              "expert",
            ].indexOf(this.userService.user.role) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: klusser");
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}

@Injectable()
export class InwinGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            [
              "inwinner",
              "valideur",
              "valideur-klusser",
              "adviseur",
              "projectbeheerder",
              "beheerder",
              "superadmin",
              "expert",
            ].indexOf(this.userService.user.role) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: inwinner");
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}

@Injectable()
export class ValideurGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            [
              "valideur",
              "valideur-klusser",
              "projectbeheerder",
              "expert",
              "beheerder",
              "superadmin",
              "expert",
            ].indexOf(this.userService.user.role) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: valideur");
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}

@Injectable()
export class ServiceGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            [
              "klusser",
              "valideur-klusser",
              "adviseur",
              "projectbeheerder",
              "beheerder",
              "superadmin",
              "expert",
            ].indexOf(this.userService.user.role) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: klusser");
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}

@Injectable()
export class PartnerGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            ["partner", "projectbeheerder", "beheerder", "superadmin"].indexOf(
              this.userService.user.role,
            ) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: klusser");
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}

@Injectable()
export class BeheerGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then(
        (user) => {
          if (
            ["projectbeheerder", "beheerder", "superadmin"].indexOf(
              this.userService.user.role,
            ) > -1
          ) {
            return resolve(true);
          } else {
            alert("Actie niet toegestaan. Minimale rol: projectbeheerder");
            return resolve(false);
          }
        },
        (err) => {
          this.router.navigate(["/"]);
          return resolve(false);
        },
      );
    });
  }
}
