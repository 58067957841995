import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthenticationComponent } from "./authentication/authentication.component";

const routes: Routes = [
  { path: "login", component: AuthenticationComponent },
  // { path: '404', component: SidebarComponent },
  // { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
