import { ClipboardModule } from "@angular/cdk/clipboard";
import { LayoutModule } from "@angular/cdk/layout";
import { DatePipe } from "@angular/common";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import "@angular/common/locales/global/nl";
import "@angular/compiler";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { Injector } from "@angular/core";
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat/";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DateAdapter } from "@angular/material/core";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/angular-ivy";
import { NgxFileDropModule } from "ngx-file-drop";
import { CancelVisitLastMinuteDialogComponent } from "src/app/components/cancel-visit-dialog/cancel-visit-dialog.component";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AdminGuard } from "./auth/admin.guard";
import {
  BeheerGuard,
  BewonerGuard,
  InwinGuard,
  KlusGuard,
  PartnerGuard,
  ServiceGuard,
  ValideurGuard,
} from "./auth/app.guard";
import { AuthGuard } from "./auth/auth.guard";
import { AuthModule } from "./auth/auth.module";
import { AuthService } from "./auth/auth.service";
import { AppMenuComponent } from "./components/app-menu/app-menu.component";
import { ChooseNameComponent } from "./components/choose-name/choose-name.component";
import { DateInfoPopupContentComponent } from "./components/date-info-popup-content/date-info-popup-content.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { FooterComponent } from "./components/footer/footer.component";
import { InfoPopupComponent } from "./components/info-popup/info-popup.component";
import { M3SideMenuComponent } from "./components/m3-side-menu/m3-side-menu.component";
import { PartnerSettingsComponent } from "./components/partner-settings/partner-settings.component";
import { PortalAdviceComponent } from "./components/portal-advice/portal-advice.component";
import { PortalClientIdentificationComponent } from "./components/portal-client-identification/portal-client-identification.component";
import { PortalReportDownloadComponent } from "./components/portal-report-download/portal-report-download.component";
import { ProjectPortalCustomisationComponent } from "./components/project-portal-customisation/project-portal-customisation.component";
import { ScanAnalysisAdviceComponent } from "./components/scan-analysis-advice/scan-analysis-advice.component";
import { WorkLogDialogComponent } from "./components/work-log-dialog/work-log-dialog.component";
import { WorkLogDeleteDialogComponent } from "./components/work-log-dialog/work-log-dialog.component";
import { CustomDateAdapter } from "./custom-date-adapter";
import { AppSelectorComponent } from "./pages/app-selector/app-selector.component";
import { ProjectFormModalComponent } from "./project-components/project-form-modal/project-form-modal.component";
import { CanvasService } from "./services/canvas.service";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { UserResolver } from "./user/user.resolver";
import { UserService } from "./user/user.service";

export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ProjectFormModalComponent,
    AppSelectorComponent,
    DialogComponent,
    WorkLogDialogComponent,
    WorkLogDeleteDialogComponent,
    CancelVisitLastMinuteDialogComponent,
    ProjectPortalCustomisationComponent,
    PortalReportDownloadComponent,
    PortalAdviceComponent,
    PortalClientIdentificationComponent,
    ScanAnalysisAdviceComponent,
    InfoPopupComponent,
    DateInfoPopupContentComponent,
    FooterComponent,
    AppMenuComponent,
    ChooseNameComponent,
    PartnerSettingsComponent,
    M3SideMenuComponent,
  ],
  imports: [
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    MatInputModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatGridListModule,
    MatMenuModule,
    FontAwesomeModule,
    CommonModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatNativeDateModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatStepperModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatDialogModule,
    MatSelectModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatRadioModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonToggleModule,
    NgxFileDropModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,
    NgbModule, // imports firebase/auth, only needed for auth features
    ClipboardModule,
    MatFormFieldModule,
  ],
  providers: [
    AuthService,
    UserService,
    UserResolver,
    AuthGuard,
    AdminGuard,
    KlusGuard,
    BewonerGuard,
    ServiceGuard,
    PartnerGuard,
    InwinGuard,
    ValideurGuard,
    BeheerGuard,
    DatePipe,
    CanvasService,
    AppMenuComponent,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    { provide: MAT_DATE_LOCALE, useValue: "nl-NL" },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [WorkLogDialogComponent],
})
export class AppModule {
  constructor(private injector: Injector, private dateAdapter: DateAdapter<Date>) {
    AppInjector = this.injector;
    this.dateAdapter.setLocale("nl-NL");
  }
}
