import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AdminGuard } from "./auth/admin.guard";
import {
  BeheerGuard,
  InwinGuard,
  KlusGuard,
  ServiceGuard,
  ValideurGuard,
} from "./auth/app.guard";
import { AuthGuard } from "./auth/auth.guard";
import { AppSelectorComponent } from "./pages/app-selector/app-selector.component";
import { SidebarComponent } from "./sidebar/sidebar.component";

const routes: Routes = [
  {
    path: "",
    component: AppSelectorComponent,
    canActivate: [AuthGuard],
    data: { title: "Energieklus" },
  },
  {
    path: "service",
    loadChildren: () =>
      import("./pages/service/service.module").then((m) => m.ServiceModule),
    canActivate: [AuthGuard, ServiceGuard],
    data: { title: "Energieklus Service" },
  },
  {
    path: "partners",
    loadChildren: () =>
      import("./pages/partners/partners.module").then((m) => m.PartnersModule),
    data: { title: "Energieklus Partners" },
  },
  {
    path: "partner-aanmelden",
    loadChildren: () =>
      import("./pages/partner-interest/partner-interest.module").then(
        (m) => m.PartnerInterestModule,
      ),
    data: { title: "Partner aanmelden" },
  },
  {
    path: "voucher/verzilveren/:voucher_id",
    loadChildren: () =>
      import("./pages/redeem-voucher-page/redeem-voucher.module").then(
        (m) => m.RedeemVoucherModule,
      ),
    canActivate: [AuthGuard],
    data: { title: "Voucher verzilveren" },
  },
  {
    path: "isde/subsidiehulp/:voucher_id",
    loadChildren: () =>
      import("./pages/isde-request-page/isde-request-page.module").then(
        (m) => m.ISDERequestPageModule,
      ),
    canActivate: [AuthGuard],
    data: { title: "ISDE Subsidiehulp" },
  },
  {
    path: "fix",
    component: SidebarComponent,
    canActivate: [AuthGuard, KlusGuard],
    data: { title: "Energieklus Fix" },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/fix-projects/fix-projects.module").then(
            (m) => m.FixProjectsModule,
          ),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./pages/fix-dashboard/fix-dashboard.module").then(
            (m) => m.FixDashboardModule,
          ),
        canActivate: [AdminGuard],
      },

      {
        path: "navigation",
        loadChildren: () =>
          import("./pages/fix-navigation/fix-navigation.module").then(
            (m) => m.FixNavigationModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "survey",
        loadChildren: () =>
          import("./pages/fix-survey/fix-survey.module").then((m) => m.FixSurveyModule),
        canActivate: [KlusGuard],
      },
      {
        path: "projects",
        loadChildren: () =>
          import("./pages/fix-projects/fix-projects.module").then(
            (m) => m.FixProjectsModule,
          ),
        canActivate: [BeheerGuard],
      },
      {
        path: "planning",
        loadChildren: () =>
          import("./pages/fix-planning/fix-planning.module").then(
            (m) => m.FixPlanningModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "analytics",
        loadChildren: () =>
          import("./pages/fix-analytics/fix-analytics.module").then(
            (m) => m.FixAnalyticsModule,
          ),
        canActivate: [BeheerGuard],
      },
      {
        path: "my-inventory",
        loadChildren: () =>
          import("./pages/my-inventory/my-inventory.module").then(
            (m) => m.MyInventoryModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./pages/fix-inventory/fix-inventory.module").then(
            (m) => m.FixInventoryModule,
          ),
        canActivate: [BeheerGuard],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
      {
        path: "activity-feed",
        loadChildren: () =>
          import("./pages/fix-activity-feed/fix-activity-feed.module").then(
            (m) => m.FixActivityFeedModule,
          ),
        canActivate: [BeheerGuard],
      },
    ],
  },
  {
    path: "scan",
    component: SidebarComponent,
    canActivate: [AuthGuard, InwinGuard],
    data: { title: "Energieklus Warmtescans" },
    children: [
      { path: "", redirectTo: "projects", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [AdminGuard],
      },
      {
        path: "validation",
        loadChildren: () =>
          import("./pages/validation/validation.module").then(
            (m) => m.ValidationComponentModule,
          ),
        canActivate: [ValideurGuard],
      },

      {
        path: "preselect",
        loadChildren: () =>
          import("./pages/preselect/pre-select.module").then((m) => m.PreSelectModule),
      },
      {
        path: "projects",
        loadChildren: () =>
          import("./pages/projects/projects.module").then((m) => m.ProjectsModule),
        canActivate: [BeheerGuard],
      },
      {
        path: "analytics",
        loadChildren: () =>
          import("./pages/analytics/analytics.module").then((m) => m.AnalyticsModule),
        canActivate: [ValideurGuard],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
      {
        path: "acquisition",
        loadChildren: () =>
          import("./pages/acquisition/acquisition.module").then(
            (m) => m.AcquisitionModule,
          ),
        canActivate: [InwinGuard],
      },
    ],
  },
  {
    path: "coach",
    component: SidebarComponent,
    canActivate: [AuthGuard],
    data: { title: "Energieklus Advies" },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/coach/projects/coach-projects.module").then(
            (m) => m.CoachProjectsModule,
          ),
      },
      {
        path: "navigation",
        loadChildren: () =>
          import("./pages/coach/navigation/coach-navigation.module").then(
            (m) => m.CoachNavigationModule,
          ),
        canActivate: [KlusGuard],
      },
      // Name is incorrect but it should be like this
      {
        path: "survey",
        loadChildren: () =>
          import("./pages/coach/survey/coach-survey.module").then(
            (m) => m.CoachSurveyModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "activity-feed",
        loadChildren: () =>
          import("./pages/coach/activity-feed/coach-activity-feed.module").then(
            (m) => m.CoachActivityFeedModule,
          ),
        canActivate: [BeheerGuard],
      },
      {
        path: "my-inventory",
        loadChildren: () =>
          import("./pages/coach/inventory/coach-inventory.module").then(
            (m) => m.CoachInventoryModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "analytics",
        loadChildren: () =>
          import("./pages/coach/analytics/coach-analytics.module").then(
            (m) => m.CoachAnalyticsComponentModule,
          ),
        canActivate: [ValideurGuard],
      },
      {
        path: "planning",
        loadChildren: () =>
          import("./pages/coach/planning/coach-planning.module").then(
            (m) => m.CoachPlanningModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
    ],
  },
  {
    path: "check",
    component: SidebarComponent,
    canActivate: [AuthGuard],
    data: { title: "Energieklus Woningcheck" },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/check/projects/check-projects.module").then(
            (m) => m.CheckProjectsModule,
          ),
      },
      {
        path: "navigation",
        loadChildren: () =>
          import("./pages/check/navigation/check-navigation.module").then(
            (m) => m.CheckNavigationModule,
          ),
        canActivate: [KlusGuard],
      },
      // Name is incorrect but it should be like this
      {
        path: "survey",
        loadChildren: () =>
          import("./pages/check/survey/check-survey.module").then(
            (m) => m.CheckSurveyModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "activity-feed",
        loadChildren: () =>
          import("./pages/check/activity-feed/check-activity-feed.module").then(
            (m) => m.CheckActivityFeedModule,
          ),
        canActivate: [BeheerGuard],
      },
      {
        path: "planning",
        loadChildren: () =>
          import("./pages/check/planning/check-planning.module").then(
            (m) => m.CheckPlanningModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
    ],
  },
  {
    path: "voucher",
    component: SidebarComponent,
    canActivate: [AuthGuard],
    data: { title: "Energieklus Voucher" },
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/voucher/projects/voucher-projects.module").then(
            (m) => m.VoucherProjectsModule,
          ),
      },
      {
        path: "navigation",
        loadChildren: () =>
          import("./pages/voucher/navigation/voucher-navigation.module").then(
            (m) => m.VoucherNavigationModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "activity-feed",
        loadChildren: () =>
          import("./pages/voucher/activity-feed/voucher-activity-feed.module").then(
            (m) => m.VoucherActivityFeedModule,
          ),
        canActivate: [BeheerGuard],
      },
      {
        path: "planning",
        loadChildren: () =>
          import("./pages/voucher/planning/voucher-planning.module").then(
            (m) => m.VoucherPlanningModule,
          ),
        canActivate: [KlusGuard],
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AdminGuard],
      },
    ],
  },
  // Widgets accessed externally
  {
    path: "aanmelden/:project_id",
    loadChildren: () =>
      import("./pages/energieklus-sign-up/energieklus-sign-up.module").then(
        (m) => m.EnergieklusSignUpModule,
      ),
  }, // This is the widget used on energieklus.nl We default this widget to the type 'fix' in the backend.
  {
    path: "voucher/project/activeren",
    loadChildren: () =>
      import("./pages/activate-voucher/activate-voucher.module").then(
        (m) => m.ActivateVoucherModule,
      ),
  }, // This is the widget used on energieklus.nl We default this widget to the type 'fix' in the backend.
  // Widgets accessed by ???
  { path: "aanmeldformulier", redirectTo: "fix/aanmelden" }, // Replaces the old router so that it is consistent
  {
    path: "voucher/activeren",
    loadChildren: () =>
      import("./components/voucher-sign-up-form/voucher-sign-up-form.module").then(
        (m) => m.VoucherSignUpFormModule,
      ),
  }, // This is new to support coach signups.
  {
    path: "voucher/aanmaken",
    loadChildren: () =>
      import("./pages/create-voucher/create-voucher.module").then(
        (m) => m.CreateVoucherModule,
      ),
    canActivate: [BeheerGuard],
  }, // This is new to support coach signups.
  {
    path: "aanmelden",
    loadChildren: () =>
      import("./pages/energieklus-sign-up/energieklus-sign-up.module").then(
        (m) => m.EnergieklusSignUpModule,
      ),
  }, // This is the widget used on energieklus.nl We default this widget to the type 'fix' in the backend.

  // Widgets accessed by ???
  { path: "aanmeldformulier", redirectTo: "fix/aanmelden" }, // Replaces the old router so that it is consistent
  {
    path: "fix/aanmelden",
    loadChildren: () =>
      import("./pages/fix-sign-up/fix-sign-up.module").then((m) => m.FixSignUpModule),
  }, // This is a "new" router that replaces /aanmeldformulier to that we know the project type from the router.
  {
    path: "coach/aanmelden",
    loadChildren: () =>
      import("./pages/coach-sign-up/coach-sign-up.module").then(
        (m) => m.CoachSignUpModule,
      ),
  }, // This is new to support coach signups.
  {
    path: "check/aanmelden",
    loadChildren: () =>
      import("./pages/check-sign-up/check-sign-up.module").then(
        (m) => m.CheckSignUpModule,
      ),
  }, // This is new to support coach signups.
  {
    path: "check/project/aanmelden",
    loadChildren: () =>
      import("./pages/energieklus-sign-up/energieklus-sign-up.module").then(
        (m) => m.EnergieklusSignUpModule,
      ),
  },
  {
    path: "scan/aanmelden",
    loadChildren: () =>
      import("./components/scan-sign-up-form/scan-sign-up-form.module").then(
        (m) => m.ScanSignUpFormModule,
      ),
  },
  // Signup widget for scan and or fix. Not sure where used now. Should have fix or scan in the url.
  {
    path: "opt-portal",
    loadChildren: () =>
      import("./pages/opt-portal/opt-portal.module").then((m) => m.OptPortalModule),
  },
  // These are the sigupform used in the application.
  {
    path: "fix/registration",
    loadChildren: () =>
      import("./pages/fix-registration/fix-registration.module").then(
        (m) => m.FixRegistrationModule,
      ),
    data: { title: "Aanmelden" },
  },
  {
    path: "coach/registration",
    loadChildren: () =>
      import("./pages/fix-registration/fix-registration.module").then(
        (m) => m.FixRegistrationModule,
      ),
    data: { title: "Aanmelden" },
  },
  {
    path: "check/registration",
    loadChildren: () =>
      import("./pages/fix-registration/fix-registration.module").then(
        (m) => m.FixRegistrationModule,
      ),
    data: { title: "Aanmelden" },
  },
  {
    path: "project-portal",
    loadChildren: () =>
      import("./pages/project-portal/project-portal.module").then(
        (m) => m.ProjectPortalModule,
      ),
    data: { title: "Energieklus Project Portal" },
  },
  {
    path: "dossier",
    loadChildren: () =>
      import("./pages/dossier/dossier.module").then((m) => m.DossierModule),
    data: { title: "Energieklus dossier" },
  },
  {
    path: "coach/survey-resident",
    data: { title: "Energieklus advies" },
    loadChildren: () =>
      import("./pages/coach/survey-resident/coach-survey-resident.module").then(
        (m) => m.CoachSurveyResidentModule,
      ),
  },
  {
    path: "feedback/:contract_id",
    loadChildren: () =>
      import("./pages/customer-feedback/customer-feedback.module").then(
        (m) => m.CUstomerFeedbackModule,
      ),
  },
  {
    path: "bewoners/feedback/:visitation_id",
    loadChildren: () =>
      import("./pages/resident-feedback/resident-feedback.module").then(
        (m) => m.ResidentFeedbackModule,
      ),
  },
  {
    path: "warmtescan/feedback",
    loadChildren: () =>
      import("./pages/scan-feedback/scan-feedback.module").then(
        (m) => m.ScanFeedbackModule,
      ),
  },
  {
    path: "login",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    data: { title: "Login" },
  },
  { path: "**", redirectTo: "fix" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "top",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
