import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/user/user.service";

@Component({
  selector: "app-app-selector",
  templateUrl: "./app-selector.component.html",
  styleUrls: ["./app-selector.component.scss"],
})
export class AppSelectorComponent implements OnInit {
  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      if (["inwinner", "valideur", "expert"].indexOf(this.userService.user.role) > -1) {
        this.router.navigate(["scan", "acquisition"], {
          relativeTo: this.route,
        });
      }
    }, 100);

    setTimeout(() => {
      if (
        this.userService.user.role === "bewoner" &&
        !this.route.snapshot.url.some((segment) => segment.path === "survey-resident")
      ) {
        this.router.navigate(["dossier"], { relativeTo: this.route });
      }
    }, 100);
  }

  scanRouteForRole() {
    if (
      ["projectbeheerder", "beheerder", "superadmin"].indexOf(
        this.userService.user.role,
      ) > -1
    ) {
      this.router.navigate(["scan", "projects"], { relativeTo: this.route });
    } else if (this.userService.user.role === "inwinner") {
      this.router.navigate(["scan", "inwinning"], { relativeTo: this.route });
    } else if (this.userService.user.role === "valideur") {
      this.router.navigate(["scan", "validation"], { relativeTo: this.route });
    } else if (this.userService.user.role === "klusser") {
      this.router.navigate(["fix", "navigation"], { relativeTo: this.route });
    } else {
      this.router.navigate(["scan", "validation"], { relativeTo: this.route });
    }
  }
}
