import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import firebase from "firebase/compat";
import { catchError, map, retry } from "rxjs/operators";

import { UserService } from "../../user/user.service";
import { CONTENT_TYPE, RESPONSE_TYPE, Request } from "./request";

@Injectable({
  providedIn: "root",
})
export class ApiHeatbrainService {
  constructor(
    public userService: UserService,
    private http: HttpClient /* private store: Store<{ user$: User }> */,
  ) {}

  public get<T>(req: Request, errorCallback): Promise<T> {
    if (errorCallback === undefined) {
      errorCallback = this.handleError.bind(this);
    }

    return this.userService
      .getCurrentUser()
      .then((user) => user.getIdToken(false))
      .then((idToken) =>
        this.http
          .get<T>(req.getUrl(), req.getOptions(idToken))
          .pipe(retry(0), catchError(errorCallback))
          .toPromise(),
      );
  }

  public get_public<T>(req: Request, errorCallback): Promise<T> {
    const getReq = (idToken: string) =>
      this.http.get<T>(req.getUrl(), req.getOptions(idToken));
    return this.do_optional_auth<T>(getReq, errorCallback);
  }

  public get_basic<T>(
    req: Request,
    username: string,
    password: string,
    errorCallback,
    responseType: RESPONSE_TYPE = RESPONSE_TYPE.JSON,
  ): Promise<T> {
    const headers = {
      Content_Type: CONTENT_TYPE.JSON,
      Authorization: `Basic ${btoa(username + ":" + password)}`,
    };
    const obs = this.http.get<T>(req.getUrl(), {
      headers: headers,
      responseType: responseType as "json",
    });
    return obs.toPromise();
  }

  public post<T>(req: Request, errorCallback): Promise<T> {
    if (errorCallback == undefined) {
      errorCallback = this.handleError.bind(this);
    }

    return this.userService
      .getCurrentUser()
      .then((user) => user.getIdToken(false))
      .then((idToken) =>
        this.http
          .post<T>(req.getUrl(), req.getData(), req.getOptions(idToken))
          .pipe(retry(0), catchError(errorCallback))
          .toPromise(),
      );
  }

  public post_public<T>(req: Request, errorCallback): Promise<T> {
    const postReq = (idToken: string) =>
      this.http.post<T>(req.getUrl(), req.getData(), req.getOptions(idToken));
    return this.do_optional_auth<T>(postReq, errorCallback);
  }

  public post_basic<T>(
    req: Request,
    username: string,
    password: string,
    errorCallback,
  ): Promise<T> {
    const auth = `Basic ${btoa(username + ":" + password)}`;
    const headers = {
      Content_Type: CONTENT_TYPE.JSON,
      Authorization: auth,
    };
    const obs = this.http.post<T>(req.getUrl(), req.getData(), {
      headers: headers,
      responseType: RESPONSE_TYPE.JSON,
    });
    return obs.toPromise();
  }

  public put<T>(req: Request, errorCallback): Promise<T> {
    if (errorCallback == undefined) {
      errorCallback = this.handleError.bind(this);
    }

    return this.userService
      .getCurrentUser()
      .then((user) => user.getIdToken(false))
      .then((idToken) =>
        this.http
          .put<T>(req.getUrl(), req.getData(), req.getOptions(idToken))
          .pipe(retry(0), catchError(errorCallback))
          .toPromise(),
      );
  }

  public put_basic<T>(
    req: Request,
    username: string,
    password: string,
    errorCallback,
  ): Promise<T> {
    const auth = `Basic ${btoa(username + ":" + password)}`;
    const headers = {
      Content_Type: CONTENT_TYPE.JSON,
      Authorization: auth,
    };
    const obs = this.http.put<T>(req.getUrl(), req.getData(), {
      headers: headers,
      responseType: RESPONSE_TYPE.JSON,
    });
    return obs.toPromise();
  }

  public put_public<T>(req: Request, errorCallback): Promise<T> {
    const putReq = (idToken: string) =>
      this.http.put<T>(req.getUrl(), req.getData(), req.getOptions(idToken));
    return this.do_optional_auth<T>(putReq, errorCallback);
  }

  public delete<T>(req: Request, errorCallback): Promise<T> {
    if (errorCallback == undefined) {
      errorCallback = this.handleError.bind(this);
    }

    return this.userService
      .getCurrentUser()
      .then((user) => user.getIdToken(false))
      .then((idToken) =>
        this.http
          .delete<T>(req.getUrl(), req.getOptions(idToken))
          .pipe(retry(0), catchError(errorCallback))
          .toPromise(),
      );
  }

  public delete_basic<T>(
    req: Request,
    username: string,
    password: string,
    errorCallback,
  ): Promise<T> {
    const auth = `Basic ${btoa(username + ":" + password)}`;
    const headers = {
      Content_Type: CONTENT_TYPE.JSON,
      Authorization: auth,
    };
    console.log(req.getUrl());
    const obs = this.http.delete<T>(req.getUrl(), {
      headers: headers,
      responseType: RESPONSE_TYPE.JSON,
    });
    return obs.toPromise();
  }

  public delete_public<T>(req: Request, errorCallback): Promise<T> {
    const delReq = (idToken: string) =>
      this.http.delete<T>(req.getUrl(), req.getOptions(idToken));
    return this.do_optional_auth<T>(delReq, errorCallback);
  }

  public requestFactory() {
    return new Request();
  }

  handleError(error: HttpErrorResponse) {
    console.error("An error occured when making a request: ", error);
  }

  private do_optional_auth<T>(request, errorCallback): Promise<T> {
    if (errorCallback === undefined) {
      errorCallback = this.handleError.bind(this);
    }

    // Create Promise that gets the id token and returns an empty string on failure,
    // allowing the request to continue for non-logged in users too
    const optionallyGetIdToken = (user: firebase.User | null): Promise<string> => {
      try {
        return user.getIdToken(false);
      } catch {
        return Promise.resolve("");
      }
    };

    // Add extras and do request
    const doRequest = (idToken: string): Promise<T> => {
      return request(idToken).pipe(retry(0), catchError(errorCallback)).toPromise();
    };

    return this.userService
      .getCurrentUser(true)
      .then(optionallyGetIdToken)
      .then(doRequest);
  }
}
