import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationEnd } from "@angular/router";

@Component({
  selector: "app-app-menu",
  templateUrl: "./app-menu.component.html",
  styleUrls: ["./app-menu.component.css"],
})
export class AppMenuComponent implements OnInit {
  currentApp: string = "other";
  @Output() appChanged = new EventEmitter<string>();

  constructor(public router: Router) {
    // Subscribe to router events
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateCurrentApp(event.url);
      }
    });
  }
  ngOnInit() {
    // Initial update
    this.updateCurrentApp(this.router.url);
  }

  private updateCurrentApp(url: string) {
    let app;
    //  split the url on '/' to make sure it does not fail if "fix" mentioned anywhere in the url
    url = url.split("/")[1];
    if (url.includes("scan")) {
      app = "scan";
    } else if (url.includes("fix")) {
      app = "fix";
    } else if (url.includes("coach")) {
      app = "coach";
    } else if (url.includes("check")) {
      app = "check";
    } else if (url.includes("voucher")) {
      app = "voucher";
    } else {
      app = "other";
    }
    if (app !== this.currentApp) {
      this.currentApp = app;
      this.appChanged.emit(this.currentApp); // Emit the event
    }
  }
}
