<div *ngIf="!resetPassword" class="login-container">
  <!-- <h2 class="login-title">Inloggen</h2> -->

  <form action="#" method="post">
    <p class="auth-error" *ngIf="errorMessage">{{ errorMessage }}</p>

    <div class="login-input">
      <label for="email" class="login-label">
        <input
          id="email"
          [(ngModel)]="email"
          (input)="handleEmailChange($event)"
          class="login-text-input"
          placeholder="E-mailadres"
          type="email"
          name="email"
          required
        />
      </label>
    </div>

    <div class="login-input">
      <label for="password" class="login-label">
        <input
          id="password"
          (input)="handlePasswordChange($event)"
          class="login-text-input"
          placeholder="Wachtwoord"
          type="password"
          name="password"
          required
        />
      </label>
    </div>

    <button type="submit" (click)="tryLogin()" class="login-btn">Inloggen</button>

    <div class="toggle-link" (click)="toggleResetPassword()">Wachtwoord vergeten?</div>
  </form>
  <hr />
  <p class="floating-or"><span>of</span></p>
  <button
    (click)="tryGoogleLogin()"
    data-test="sign-in-btn"
    class="social-btn google-btn"
  >
    <span class="social-icon"
      ><img width="22" height="22" src="/assets/img/google_icon.png"
    /></span>
    <span class="social-text">Ga door met Google</span>
    <span class="spacer"></span>
  </button>
</div>
<div *ngIf="resetPassword" class="login-container">
  <form action="/reset-form" method="post">
    <p class="auth-error" *ngIf="errorMessage">{{ errorMessage }}</p>
    <p class="auth-success" *ngIf="successMessage">{{ successMessage }}</p>

    <div *ngIf="!successMessage" class="login-input">
      <label for="email" class="login-label">
        <input
          id="email"
          (input)="handleEmailChange($event)"
          class="login-text-input"
          placeholder="E-mailadres"
          type="email"
          name="email"
          required
        />
      </label>
    </div>

    <button
      *ngIf="!successMessage"
      type="submit"
      (click)="sendPasswordResetLink()"
      class="login-btn"
    >
      Stuur herstel link
    </button>
  </form>
  <div class="toggle-link" (click)="toggleResetPassword()">Ga terug</div>
</div>
