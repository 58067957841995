import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import posthog from 'posthog-js';

if (environment.env === 'production') {
     posthog.init('phc_oGlsIhrYSFgrbHFHPErFzAlRUU6VJP14B9uLdWbbIB2', { api_host: 'https://eu.posthog.com' });
}

if (environment.env != "dev") {
Sentry.init({
  dsn: "https://ba8c015a82984d8abbaa471ed43895bb@sentry.sobolt.com/4",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://test.samen.energieklus.nl",
    "https://test.samen.heatpulse.nl",
    "https://accept.samen.energieklus.nl",
    "https://accept.samen.heatpulse.nl",
    "https://training.samen.energieklus.nl",
    "https://samen.energieklus.nl",
    "https://samen.heatpulse.nl",
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
   // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
  //profilesSampleRate: 0.5,
  release: environment.version,
  environment: environment.env,
});
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// applyPolyfills().then(() => {
//   defineCustomElements()
// });
