import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { Project } from "../../interfaces/api-heatbrain";
import { ProjectService } from "../../services/project.service";

@Component({
  selector: "app-project-form-modal",
  templateUrl: "./project-form-modal.component.html",
  styleUrls: ["./project-form-modal.component.css"],
})
export class ProjectFormModalComponent implements OnInit {
  @Input() edit_mode: string;
  @Input() project: Project = new Project();
  @Input() project_default_opt_val: string = "opt-in";

  new_project: Project = new Project();
  title_text: string = "";
  submit_button_text: string = "";

  constructor(
    public projectService: ProjectService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (this.edit_mode == "create") {
      this.title_text = "Maak een nieuw project aan";
      this.submit_button_text = "Aanmaken";
    } else {
      this.title_text = "Edit project";
      this.submit_button_text = "Save project";
    }
  }

  public post_project(): void {
    if (this.edit_mode == "create") {
      this.create_project(
        this.new_project.name,
        this.project_default_opt_val,
        this.new_project.project_type,
      );
    } else {
      this.update_project(this.new_project);
    }
  }

  public setProjectType(type: string) {
    this.new_project.project_type = type;
    this.project.project_type = type;
  }

  private create_project(name: string, opt_type: string, project_type: string): void {
    this.projectService.create_project(name, opt_type, project_type);
    this.activeModal.close("create project click");
  }

  private update_project(new_project: Project): void {
    this.projectService.update_project(new_project);
    this.activeModal.close("update project click");
  }

  private shallow_copy(obj: any): any {
    return { ...obj };
  }
}
