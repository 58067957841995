<div class="welcome-display">
  <div class="header">
    <div class="title">
      <img
        src="assets/img/energieklus_logo.png"
        style="max-width: 60%; margin-bottom: 30px"
        alt="Energiekus logo icon of two house outlines overlapped."
      />
    </div>
    <app-login></app-login>
  </div>
</div>
<div class="home-img"></div>
<app-cookie-consent-banner></app-cookie-consent-banner>
