<!-- TODO: REPLACE COACH BUTTON DISABLED WITH THIS "currentApp === 'coach'" -->

<button
  mat-menu-item
  [routerLink]="['/scan/projects']"
  [disabled]="currentApp === 'scan'"
  data-testid="scan-button"
>
  <mat-icon>camera_enhance</mat-icon>
  <span>Scan</span>
</button>
<button
  mat-menu-item
  [routerLink]="['/fix/navigation']"
  [disabled]="currentApp === 'fix'"
  data-testid="fix-button"
>
  <mat-icon>aspect_ratio</mat-icon>
  <span>Fix</span>
</button>
<button
  mat-menu-item
  [routerLink]="['/coach/navigation']"
  [disabled]="currentApp === 'coach'"
  data-testid="coach-button"
>
  <mat-icon>forum</mat-icon>
  <span>Advies</span>
</button>

<button
  mat-menu-item
  [routerLink]="['/check/navigation']"
  [disabled]="currentApp === 'check'"
  data-testid="check-button"
>
  <mat-icon>forum</mat-icon>
  <span>Woningcheck</span>
</button>

<button
  mat-menu-item
  [routerLink]="['/voucher/navigation']"
  [disabled]="currentApp === 'voucher'"
  data-testid="voucher-button"
>
  <mat-icon>forum</mat-icon>
  <span>Voucher</span>
</button>
