import { environment } from "../../../environments/environment";
import { UrlBuilder } from "./url-builder";

export enum CONTENT_TYPE {
  FORM = "multipart/form-data",
  JSON = "application/json",
  TEXT = "text/html",
  JPEG = "image/jpeg",
  PNG = "image/png",
  PDF = "application/pdf",
}

export enum RESPONSE_TYPE {
  BLOB = "blob",
  JSON = "json",
  TEXT = "text",
  ARRAY_BUFFER = "arraybuffer",
}

export class Request {
  constructor() {}

  private urlBuilder: UrlBuilder = new UrlBuilder(environment.BACKEND_BASE_URL);
  private body: any;
  private contentType: CONTENT_TYPE;
  private responseType: RESPONSE_TYPE;
  private formData: FormData | null = null;

  public setUrlParameters(pathVariables: any[] = []): string {
    let string_array = [];
    pathVariables.forEach((element: any) => {
      if (typeof element == "string") {
        string_array.push(element);
      } else {
        string_array.push(element.toString());
      }
    });
    let pathUrl: string = string_array.join("/");
    // console.log(pathUrl);
    this.urlBuilder.setPath(pathUrl);
    return this.urlBuilder.toString();
  }

  public setQueryParameters(queryParameters: any = {}): string {
    this.urlBuilder.clearQueryParams();
    for (let [key, value] of Object.entries(queryParameters)) {
      if (Array.isArray(value)) {
        value.forEach((element: any) => {
          this.urlBuilder.appendQueryParam(key, element);
        });
      } else {
        this.urlBuilder.appendQueryParam(key, value);
      }
    }
    return this.urlBuilder.toString();
  }

  public appendQueryParameters(queryParameters: { [key: string]: string }) {
    for (let [key, value] of Object.entries(queryParameters)) {
      this.urlBuilder.appendQueryParam(key, value);
    }
    return this.urlBuilder.toString();
  }

  public setArrayQueryParameter(
    key: string,
    values: any[],
    append: boolean = false,
  ): string {
    // Creates a query url out of an array of queries in a form expected by FastAPI, namely all
    // query parameters are named the same. If append is true any prior query parameters added
    // to the url are not removed.
    if (!append) this.urlBuilder.clearQueryParams();
    values.forEach((value) => this.urlBuilder.appendQueryParam(key, value));
    return this.urlBuilder.toString();
  }

  public getUrl(): string {
    return this.urlBuilder.toString();
  }

  public setRequestBody(body: any): any {
    this.body = body;
    return this.body;
  }

  public setHeaders(c?: CONTENT_TYPE, r?: RESPONSE_TYPE): void {
    this.contentType = c ? c : CONTENT_TYPE.JSON;
    this.responseType = r ? r : RESPONSE_TYPE.JSON;
  }

  public resetVars(): void {
    this.urlBuilder = new UrlBuilder(environment.BACKEND_BASE_URL);
    this.body = void 0;
    this.contentType = void 0;
    this.responseType = void 0;
  }

  public getOptions(idToken): any {
    let options = {
      headers: {
        Content_Type: this.contentType,
        Authorization: `Bearer ${idToken}`,
      },
      responseType: this.responseType,
    };

    return options;
  }

  public setFormData(formData: FormData) {
    //This bypasses the setBody and getData combo to make it
    //easier to define more complicated FormDatas for multi file upload.
    this.formData = formData;
  }

  public getData(): any {
    if (this.formData) {
      return this.formData;
    }

    if (this.contentType == CONTENT_TYPE.FORM) {
      let formData = new FormData();
      for (let [key, value] of Object.entries(this.body)) {
        if (value instanceof File) {
          formData.append(key, value, value.name);
        } else if (value instanceof Blob) {
          formData.append(key, value);
        } else if (Array.isArray(value)) {
          let string_array = [];
          value.forEach((element: any) => {
            if (typeof element == "string") {
              string_array.push(element);
            } else {
              string_array.push(element.toString());
            }
          });
          formData.append(key, string_array.join(","));
        } else if (typeof value == "string") {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
      }
      return formData;
    } else {
      return this.body;
    }
  }
}
