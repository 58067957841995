import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AuthenticationComponent } from "../auth/authentication/authentication.component";
import { CookieConsentBannerComponent } from "../components/cookie-consent-banner/cookie-consent-banner.component";
import { LoginComponent } from "../components/login/login.component";
import { RegisterComponent } from "../components/register/register.component";

@NgModule({
  declarations: [
    AuthenticationComponent,
    LoginComponent,
    RegisterComponent,
    CookieConsentBannerComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    AuthenticationComponent,
    LoginComponent,
    RegisterComponent,
    CookieConsentBannerComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SharedLoginModule {}
